/**
 * Represents an generic key value for storage for indexed db
 */
export class GenericLookUpStorageDTO<T> {
    /**
     * The key of the record
     */
    key!: string;

    /**
     * The value of the record
     */
    value!: T;

    constructor(key: string, value: T) {
        this.key = key;
        this.value = value;
    }
}
