/**
 * Represents an organization.
 */
export class OrganizationDTO {
    /**
     * The ID of the organization.
     */
    id!: number;

    /**
     * The name of the organization.
     */
    name!: string;

    /**
     * The unit of the organization.
     */
    unit!: string;
}
