/**
 * Represents the base repository for getting server time ticks
 */
export abstract class ServerTimeTicksBaseRepository {
    /**
     * Get server time ticks
     * @returns returns server time ticks
     */
    abstract getServerTimeTicks(): Promise<number>;
}
