import { Injectable, inject } from "@angular/core";
import { EncryptedData } from "../model/encrypted-data.model";
import { environment } from "../../../../environments/environment";
import { ILogger } from "../../../core/logging/models/logger.model";
import { LOGGER } from "../../../core/logging/providers/logger.provider";

/**
 * Utility service that provides various helper methods for encryption, compression, and other operations.
 */
@Injectable({
    providedIn: "root",
})
export class CipherUtilService {
    private logger: ILogger = inject(LOGGER);
    private byteToHex: string[] = [];

    constructor() {
        this.buildByteToHex();
    }

    private buildByteToHex(): void {
        for (let n = 0; n < 0xff; n++) {
            const hexOctet = n.toString(16).padStart(2, "0");
            this.byteToHex.push(hexOctet);
        }
    }

    /**
     * Encrypts a string value using the provided key.
     * Validated using {@link https://emn178.github.io/online-tools/sha256.html}
     * @param value String to encrypt.
     * @param asBase64 Whether to return the encrypted value as a base64 (true) or hex (false) string.
     * @returns The encrypted value.
     */
    async getSha256String(
        value: string,
        asBase64: boolean = false
    ): Promise<string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(value);
        const key = await window.crypto.subtle.digest("SHA-256", data);

        if (asBase64) {
            return btoa(
                String.fromCharCode.apply(
                    null,
                    Array.from<number>(new Uint8Array(key))
                )
            );
        } else {
            return this.toHexString(key);
        }
    }

    private toHexString(byteArray: ArrayBuffer): string {
        const buff = new Uint8Array(byteArray);
        const hexOctets = new Array(buff.length);

        for (let i = 0; i < buff.length; i++) {
            hexOctets[i] = this.byteToHex[buff[i]];
        }

        return hexOctets.join("");
    }

    /**
     * Encrypts a string value using the provided key, based on environment encryption configuration.
     * @param value The string to encrypt. If encrypting objects remember to {@link JSON.stringify} first.
     * @param key The secret key used to encrypt the value. If the key is longer than the environment encryption key length,
     *  it will be truncated. If it is shorter, it will be padded with 'x' characters.
     * @param useCompression Whether to compress the value before encrypting it. Default is true.
     * @returns The encrypted data.
     */
    async encrypt(
        value: string,
        key: string,
        useCompression: boolean = true
    ): Promise<EncryptedData> {
        try {
            let resizedKey = key;
            if (resizedKey.length >= environment.encryption.keyLength) {
                resizedKey = resizedKey.substring(
                    0,
                    environment.encryption.keyLength
                );
            } else {
                resizedKey = resizedKey.padStart(
                    environment.encryption.keyLength,
                    "x"
                );
            }

            const iv = window.crypto.getRandomValues(
                new Uint8Array(environment.encryption.ivLength)
            );

            const textEncoder = new TextEncoder();
            let encodedValue = textEncoder.encode(value);
            const encodedKey = textEncoder.encode(resizedKey);

            if (useCompression) {
                encodedValue = await this.compress(encodedValue);
            }

            const cryptoKey = await crypto.subtle.importKey(
                "raw",
                encodedKey,
                environment.encryption.cipher,
                false,
                ["encrypt"]
            );

            const encryptionParams = {
                name: environment.encryption.cipher,
                counter: iv,
                length: environment.encryption.counterBlockLength,
            };

            const encryptedData = await crypto.subtle.encrypt(
                encryptionParams,
                cryptoKey,
                encodedValue
            );

            return new EncryptedData(
                Array.from(new Uint8Array(encryptedData)),
                iv
            );
        } catch (error) {
            this.logger.error("Error encrypting data", error);
            throw error;
        }
    }

    /**
     *
     * @param value Uncompressed ArrayBuffer
     * @returns Compressed Uint8Array
     * @throws {Error}
     */
    async compress(value: ArrayBuffer): Promise<Uint8Array> {
        return await this.processStream(new CompressionStream("gzip"), value);
    }

    /**
     * Processes a stream using the provided input.
     * @param stream The stream to process. Either a CompressionStream or DecompressionStream.
     * @param input The input to process.
     * @returns The processed output. Compressed or decompressed, depending on the stream.
     * @throws {Error}
     */
    private async processStream(
        stream: CompressionStream | DecompressionStream,
        input: ArrayBuffer
    ) {
        const writer = stream.writable.getWriter();
        writer.write(input);
        writer.close();

        const output: Uint8Array[] = [];
        const reader = stream.readable.getReader();
        let totalSize = 0;
        let done = false;
        let value: Uint8Array;
        while (!done) {
            const result = await reader.read();
            done = result.done;
            value = result.value;

            if (done) {
                break;
            }

            output.push(value);
            totalSize += value.length;
        }
        const concatenated = new Uint8Array(totalSize);
        let offset = 0;
        for (const chunk of output) {
            concatenated.set(chunk, offset);
            offset += chunk.length;
        }
        return concatenated;
    }

    /**
     * Decrypts an encrypted data object using the provided key.
     * @param encryptedData The encrypted data object to decrypt. Structure should be { data: number[], iv: uint8array }
     * @param key The same key used to encrypt the data.
     * @param useCompression Whether to decompress the data after decrypting it. Default is true. Should match the value used during encryption.
     * @returns The decrypted string. If you encrypted an object, remember to {@link JSON.parse} the result.
     */
    async decrypt(
        encryptedData: EncryptedData,
        key: string,
        useCompression: boolean = true
    ): Promise<string> {
        try {
            let resizedKey = key;
            if (resizedKey.length >= environment.encryption.keyLength) {
                resizedKey = resizedKey.substring(
                    0,
                    environment.encryption.keyLength
                );
            } else {
                resizedKey = resizedKey.padStart(
                    environment.encryption.keyLength,
                    "x"
                );
            }

            const textEncoder = new TextEncoder();
            const encodedKey = textEncoder.encode(resizedKey);

            const encryptedDataArray = new Uint8Array(encryptedData.data);
            // For reasons unknown to me, the parsing of the iv object to a Uint8Array
            // is not done automatically by typescript (it converts to an object with
            // the values as {0:185,1:159,...})
            // So we have to manually convert it to a Uint8Array
            const encryptedIv = new Uint8Array(Object.values(encryptedData.iv));

            const decryptionParams = {
                name: environment.encryption.cipher,
                counter: encryptedIv,
                length: environment.encryption.counterBlockLength,
            };

            const cryptoKey = await crypto.subtle.importKey(
                "raw",
                encodedKey,
                environment.encryption.cipher,
                false,
                ["decrypt"]
            );

            let decryptedData = await crypto.subtle.decrypt(
                decryptionParams,
                cryptoKey,
                encryptedDataArray
            );

            if (useCompression) {
                decryptedData = await this.decompress(decryptedData);
            }

            return new TextDecoder().decode(decryptedData);
        } catch (error) {
            this.logger.error("Error decrypting data", error);
            throw error;
        }
    }

    /**
     *
     * @param value Compressed ArrayBuffer
     * @returns Decompressed Uint8Array
     * @throws {Error}
     */
    async decompress(value: ArrayBuffer): Promise<Uint8Array> {
        return await this.processStream(new DecompressionStream("gzip"), value);
    }
}
