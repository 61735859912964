import { OrganizationDTO } from "./organization.dto";
import { UserDetailedInfoDTO } from "./user-detailed-info.dto";

/**
 * Represents an User
 */
export class UserDTO {
    /**
     * Alternate username for the user.
     */
    altUsername: string = "";

    /**
     * Cost center associated with the user.
     */
    costCenter: string | null = null;

    /**
     * Culture code for the user's preferred language.
     */
    culture: string = "en-US";

    /**
     * Currency code for the user's preferred currency.
     */
    currencyCode: string = "USD";

    /**
     * Detailed information about the user.
     */
    detailedInformation: UserDetailedInfoDTO | null = null;

    /**
     * First name of the user.
     */
    firstName: string = "";

    /**
     * Indicates whether the user is active or not.
     */
    isActive: boolean = false;

    /**
     * Indicates whether the user is an interface user.
     */
    isInterfaceUser: boolean = false;

    /**
     * Indicates whether the user has system support privileges.
     */
    isSystemSupport: boolean = false;

    /**
     * Last name of the user.
     */
    lastName: string = "";

    /**
     * Location information for the user.
     */
    locationInformation: string | null = null;

    /**
     * Mobile access status for the user.
     */
    mobileAccessStatus: number = 0;

    /**
     * Mobile number of the user.
     */
    mobileNumber: string = "";

    /**
     * Office location of the user.
     */
    office: string = "";

    /**
     * Organization associated with the user.
     */
    organization: OrganizationDTO | null = null;

    /**
     * ID of the organization associated with the user.
     */
    organizationId: number = 0;

    /**
     * IDs of the organization roles assigned to the user.
     */
    organizationRoleIds: number[] = [];

    /**
     * Organization unit of the user.
     */
    organizationUnit: string = "";

    /**
     * Status of the user.
     */
    status: number = 0;

    /**
     * Supervisor of the user.
     */
    supervisor: string = "";

    /**
     * Display name of the user.
     */
    userDisplay: string = "";

    /**
     * ID of the user.
     */
    id!: number;

    /**
     * User options.
     */
    userOptions: string | null = null;

    /**
     * Base64 encoded user picture.
     */
    userPicture64bit: string | null = null;

    /**
     * Username of the user.
     */
    username!: string;

    /**
     * Email address of the user.
     */
    email!: string;
}
