import { Injectable, inject } from "@angular/core";
import { StandardLoginUsecase } from "../../../../login/core/usecases/standard-login.usecase";
import { GetUserTokenUsecase } from "../../../../login/core/usecases/get-user-token.usecase";
import { BehaviorSubject, Observable } from "rxjs";
import { CipherUtilService } from "../../../../shared/infrastructure/services/cipher-util.service";
import { User } from "../../../../login/core/domain/user.model";
import { UserToken } from "../../../../login/core/domain/user-token.model";
import { GetServerTimeTicksUsecase } from "../../../../shared/core/usecases/get-server-time-ticks.usecase";
import { ILogger } from "../../../logging/models/logger.model";
import { LOGGER } from "../../../logging/providers/logger.provider";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { NetworkStatusService } from "../../../../shared/infrastructure/services/network-status.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private logger: ILogger = inject(LOGGER);
    private standardLoginUsecase: StandardLoginUsecase =
        inject(StandardLoginUsecase);
    private getUserTokenUsecase: GetUserTokenUsecase =
        inject(GetUserTokenUsecase);
    private cipherUtilService: CipherUtilService = inject(CipherUtilService);
    private getServerTimeTicksUsecase: GetServerTimeTicksUsecase = inject(
        GetServerTimeTicksUsecase
    );
    private networkStatusService: NetworkStatusService =
        inject(NetworkStatusService);

    private _isUserLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );
    public get isUserLoggedIn$(): Observable<boolean> {
        return this._isUserLoggedIn$.asObservable();
    }

    private currentUser: User | null = null;
    private userToken: UserToken | null = null;

    /**
     * Authentication the user, getting user token and getting user detailed information
     *  @param username username of the user
     *  @param password password of the user
     */
    async login(username: string, password: string): Promise<User> {
        this.logger.debug(`Starting login process in auth.service`);
        const start = performance.now();

        if (username.trim().length === 0 || password.trim().length === 0) {
            this.logger.error(
                `Invalid Credentials : empty username or password @auth.service.ts - login()`
            );

            throw new HttpErrorResponse({
                status: HttpStatusCode.Unauthorized,
            });
        }

        this.logger.debug(`Awaiting current user`);
        this.currentUser = await this.standardLoginUsecase.execute({
            username,
            password,
        });
        this.logger.debug(`Current user fetched successfully`);

        this.logger.debug(`Awaiting current user token`);
        this.userToken = await this.getUserTokenUsecase.execute({
            username,
            password,
        });
        this.logger.debug(`Current user token fetched successfully`);

        this._isUserLoggedIn$.next(true);

        const end = performance.now();
        this.logger.debug(
            `Login success in auth.service, it took ${end - start} milliseconds.`
        );

        return this.currentUser;
    }

    /**
     * Logout the user
     */
    logout(): void {
        this.currentUser = null;
        this.userToken = null;
        this._isUserLoggedIn$.next(false);

        this.logger.debug(`User logged out`);

        /**
         * We want to reinitialize all services to it's default state after a logout, so that it would
         *  clear out any saved data in the service properties
         */
        window.location.reload();
    }

    /**
     * Return the current logged in user
     * @returns  Return the current logged in user if present, if not returns null
     */
    getCurrentUser(): User {
        if (!this.currentUser) {
            throw new Error("User is not available");
        }

        return this.currentUser;
    }

    /**
     * Returns the user token of the logged in user
     * @returns  Return user token
     */
    getUserToken(): UserToken {
        if (!this.userToken) {
            throw new Error("User token is not available");
        }

        return this.userToken;
    }

    /**
     * Checks if the user is currently logged in.
     * @returns A boolean indicating whether the user is logged in.
     */
    isUserLoggedIn(): boolean {
        return this._isUserLoggedIn$.value;
    }

    /**
     * Get bearer token needed for API calls
     * @returns A bearer token based on the current logged in user
     */
    async getBearerToken(): Promise<string> {
        const start = performance.now();
        this.logger.debug(`Starting bearer token generation`);

        if (!this.currentUser || !this.userToken) {
            throw new Error(
                "CurrentUser or UserToken is empty. Unable to generate Bearer Token"
            );
        }

        const serverTime = await this.getServerTimeTicksUsecase.execute();

        const encryptedToken = await this.cipherUtilService.getSha256String(
            serverTime + this.userToken.key
        );

        const tokenFormat = {
            token: encryptedToken,
            userId: this.currentUser.id,
        };

        const end = performance.now();
        this.logger.debug(
            `Bearer token generated in ${end - start} milliseconds.`
        );

        return btoa(JSON.stringify(tokenFormat));
    }
}
