import { Injectable, inject } from "@angular/core";
import { Usecase } from "../../../core/base/usecase";
import { StandardLoginCredentials } from "../domain/standard-login-credentials.model";
import { UserTokenRepository } from "../../infrastructure/persistence/user-token/user-token.repository";
import { UserToken } from "../domain/user-token.model";

@Injectable({
    providedIn: "root",
})
export class GetUserTokenUsecase extends Usecase<
    StandardLoginCredentials,
    Promise<UserToken | null>
> {
    private userTokenRepository: UserTokenRepository =
        inject(UserTokenRepository);

    /**
     * Get user token
     * @param credentials credentials of the user
     * @returns user token based on users credentials
     */
    execute(credentials: StandardLoginCredentials): Promise<UserToken> {
        this.logger.debug("Executing GetUserTokenUsecase");
        return this.userTokenRepository.getUserToken(credentials);
    }
}
