import { Injectable } from "@angular/core";
import { Mapper } from "../../../core/base/mapper";
import { OrganizationDTO } from "../model/organization.dto";
import { Organization } from "../../core/domain/organization.model";

/**
 * Mapper class for converting between OrganizationDTO and Organization model
 */
@Injectable({
    providedIn: "root",
})
export class OrganizationMapper extends Mapper<OrganizationDTO, Organization> {
    /**
     * Maps from OrganizationDTO to Organization
     * @dto - The OrganizationDTO to map from.
     * @returns The mapped Organization model
     */
    override mapFrom(dto: OrganizationDTO): Organization {
        const model = new Organization();
        Object.assign(model, dto);

        return model;
    }

    /**
     * Maps from Organization model to OrganizationDTO.
     * @model organization - The Organization model to map from.
     * @returns The mapped OrganizationDTO
     */
    override mapTo(model: Organization): OrganizationDTO {
        const dto = new OrganizationDTO();
        Object.assign(dto, model);

        return dto;
    }
}
