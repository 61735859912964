import { Injectable, inject } from "@angular/core";
import { ServerTimeTicksBaseRepository } from "../../../core/base/server-time-ticks-base.repository";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { environment } from "../../../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class ServerTimeTicksRemoteRepositoryService extends ServerTimeTicksBaseRepository {
    private http = inject(HttpClient);

    /**
     * Get server time ticks
     * @returns Server time ticks
     */
    async getServerTimeTicks(): Promise<number> {
        return firstValueFrom(
            this.http.get<number>(
                environment.routes.mCase.routes.getServerTimeTicks.url
            )
        );
    }
}
