import { Injectable, inject } from "@angular/core";
import { ServerTimeTicksRemoteRepositoryService } from "../../infrastructure/persistence/server-time-ticks/server-time-ticks-remote.repository.service";
import { Usecase } from "../../../core/base/usecase";

@Injectable({
    providedIn: "root",
})
export class GetServerTimeTicksUsecase extends Usecase<void, Promise<number>> {
    private serverTimeTicksRemoteRepositoryService = inject(
        ServerTimeTicksRemoteRepositoryService
    );

    /**
     * Get server time ticks
     * @returns Server time ticks
     */
    execute(): Promise<number> {
        const start = performance.now();
        this.logger.debug("Executing GetServerTimeTicksUsecase");
        const serverTimeTicks =
            this.serverTimeTicksRemoteRepositoryService.getServerTimeTicks();

        const end = performance.now();
        this.logger.debug(
            `GetServerTimeTicks Usecase completed in ${end - start} milliseconds.`
        );

        return serverTimeTicks;
    }
}
