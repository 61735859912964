import { inject, Injectable } from "@angular/core";
import { Mapper } from "../../../core/base/mapper";
import { UserDTO } from "../model/user.dto";
import { User } from "../../core/domain/user.model";
import { OrganizationMapper } from "./organization.mapper";
import { UserDetailedInformationMapper } from "./user-detailed-information.mapper";

/**
 * Mapper class for converting between UserDTO and User model
 */
@Injectable({
    providedIn: "root",
})
export class UserMapper extends Mapper<UserDTO, User> {
    private userDetailedInformationMapper: UserDetailedInformationMapper =
        inject(UserDetailedInformationMapper);
    private organizationMapper: OrganizationMapper = inject(OrganizationMapper);
    /**
     * Maps from UserDTO to User model
     * @userDto dto - The UserDTO to map from
     * @returns The mapped User model
     */
    override mapFrom(dto: UserDTO): User {
        const model = new User();
        Object.assign(model, dto);

        return this.DTOtoModelTransformation(dto, model);
    }

    /**
     * Maps from User to UserDTO.
     * @user user - The User model to map from.
     * @returns The mapped UserDTO
     */
    override mapTo(model: User): UserDTO {
        const dto = new UserDTO();
        Object.assign(dto, model);

        return this.modelToDTOTransformation(model, dto);
    }

    /**
     * Performs the transformation from UserDTO to User model
     * @param dto - The input UserDTO
     * @param model - The output User model
     * @returns The transformed User model
     */
    private DTOtoModelTransformation(dto: UserDTO, model: User): User {
        if (dto.detailedInformation !== null) {
            model.detailedInformation =
                this.userDetailedInformationMapper.mapFrom(
                    dto.detailedInformation
                );
        }

        if (dto.organization !== null) {
            model.organization = this.organizationMapper.mapFrom(
                dto.organization
            );
        }

        return model;
    }

    /**
     * Performs the transformation from User to UserDTO
     * @param model - The input User model
     * @param dto - The output UserDTO
     * @returns The transformed UserDTO
     */
    private modelToDTOTransformation(model: User, dto: UserDTO): UserDTO {
        if (model.detailedInformation !== null) {
            dto.detailedInformation = this.userDetailedInformationMapper.mapTo(
                model.detailedInformation
            );
        }

        if (model.organization !== null) {
            dto.organization = this.organizationMapper.mapTo(
                model.organization
            );
        }

        return dto;
    }
}
