import { Injectable, inject } from "@angular/core";
import { UserTokenBaseRepository } from "../../../core/base/user-token-base.repository";
import { StandardLoginCredentials } from "../../../core/domain/standard-login-credentials.model";
import { UserToken } from "../../../core/domain/user-token.model";
import { CipherUtilService } from "../../../../shared/infrastructure/services/cipher-util.service";
import { DB_TABLES } from "../../../../core/data-access/enums/table-list.enum";
import { GenericLookUpStorageDTO } from "../../../../core/data/model/generic-look-up-storage.dto";
import { GENERIC_LOOKUP_STORAGE_KEYS } from "../../../../core/data/enum/generic-look-up-storage-keys.enum";
import { ILogger } from "../../../../core/logging/models/logger.model";
import { LOGGER } from "../../../../core/logging/providers/logger.provider";
import { AuthUtilService } from "../../../../core/auth/infrastructure/services/auth-util.service";
import { UserTokenMapper } from "../../../data/transformation/user-token.mapper";
import { EncryptedData } from "../../../../shared/infrastructure/model/encrypted-data.model";
import { UserTokenDTO } from "../../../data/model/user-token.dto";
import { DataAccess } from "../../../../infrastructure/data-access/data-access";
import { DATA_ACCESS_SERVICE } from "../../../../core/data-access/providers/data-access.provider";

@Injectable({
    providedIn: "root",
})
export class UserTokenLocalRepository extends UserTokenBaseRepository<StandardLoginCredentials> {
    private logger: ILogger = inject(LOGGER);
    private authUtilService: AuthUtilService = inject(AuthUtilService);
    private cipherUtilService: CipherUtilService = inject(CipherUtilService);
    private dataAccess: DataAccess = inject(DATA_ACCESS_SERVICE);
    private userTokenMapper: UserTokenMapper = inject(UserTokenMapper);

    /**
     * Get user token stored in indexed db
     * @returns user token
     */
    async getUserToken(
        credentials: StandardLoginCredentials
    ): Promise<UserToken> {
        const start = performance.now();
        this.logger.debug("Starting UserTokenLocalRepo getUserToken");

        this.logger.debug("Fetching user token from indexed db");
        const encryptedUserTokenLookUpDTO: GenericLookUpStorageDTO<EncryptedData> | null =
            await this.dataAccess.read(
                DB_TABLES.GENERIC_LOOKUP,
                GENERIC_LOOKUP_STORAGE_KEYS.USER_TOKEN
            );

        if (
            !encryptedUserTokenLookUpDTO ||
            !encryptedUserTokenLookUpDTO.value
        ) {
            throw new Error(
                "User token not found @ user-token-local.repository.ts - getUserToken()"
            );
        }

        const encryptedUserTokenString = encryptedUserTokenLookUpDTO.value;

        this.logger.debug("Generating private key to decrypt user token");
        const privateKey = await this.authUtilService.generatePrivateKey(
            credentials.username,
            credentials.password
        );

        this.logger.debug("Decrypting stored user token");
        const userTokenDTOString = await this.cipherUtilService.decrypt(
            encryptedUserTokenString,
            privateKey
        );

        this.logger.debug("Mapping from UserToken dto to domain model");
        const userTokenDTO: UserTokenDTO = JSON.parse(userTokenDTOString);
        const userToken = this.userTokenMapper.mapFrom(userTokenDTO);

        const end = performance.now();
        this.logger.debug(
            `UserTokenLocalRepo getUserToken completed in ${end - start} milliseconds.`
        );

        return userToken;
    }

    /**
     * Encrypt and save user token stored to indexed db
     * @param username username of the user
     * @param password password of the user
     * @param userToken token of the user
     * @returns user token
     */
    async saveUserToken(
        username: string,
        password: string,
        userToken: UserToken
    ): Promise<UserToken> {
        const start = performance.now();
        this.logger.debug("Starting UserTokenLocalRepo saveUserToken");

        this.logger.debug("Generating private key");
        const privateKey = await this.authUtilService.generatePrivateKey(
            username,
            password
        );

        this.logger.debug("Mapping userToken domain model to dto");
        const userTokenDTO = this.userTokenMapper.mapTo(userToken);

        // Encrypt the user token
        this.logger.debug("Encrypting user token");
        const userTokenDTOString = JSON.stringify(userTokenDTO);
        const encryptedUserToken = await this.cipherUtilService.encrypt(
            userTokenDTOString,
            privateKey
        );

        // Save the token for future authentication to the server
        const userTokenStorageDTO = new GenericLookUpStorageDTO(
            GENERIC_LOOKUP_STORAGE_KEYS.USER_TOKEN,
            encryptedUserToken
        );

        this.logger.debug("Saving user token");
        await this.dataAccess.create(
            DB_TABLES.GENERIC_LOOKUP,
            userTokenStorageDTO
        );

        const end = performance.now();
        this.logger.debug(
            `UserTokenLocalRepo saveUserToken completed in ${end - start} milliseconds.`
        );

        return userToken;
    }
}
