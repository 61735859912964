import { Injectable, inject } from "@angular/core";
import { LoginBaseRepository } from "../../../core/base/login-base.repository";
import { StandardLoginRemoteRepository } from "./standard-login-remote.repository";
import { StandardLoginLocalRepository } from "./standard-login-local.repository";
import { User } from "../../../core/domain/user.model";
import { StandardLoginCredentials } from "../../../core/domain/standard-login-credentials.model";
import { NetworkStatusService } from "../../../../shared/infrastructure/services/network-status.service";
import { ILogger } from "../../../../core/logging/models/logger.model";
import { LOGGER } from "../../../../core/logging/providers/logger.provider";

@Injectable({
    providedIn: "root",
})
export class StandardLoginRepository extends LoginBaseRepository<StandardLoginCredentials> {
    private logger: ILogger = inject(LOGGER);
    private remoteRepository: StandardLoginRemoteRepository = inject(
        StandardLoginRemoteRepository
    );
    private localRepository: StandardLoginLocalRepository = inject(
        StandardLoginLocalRepository
    );
    private networkStatusService: NetworkStatusService =
        inject(NetworkStatusService);

    /**
     * Login the user with either remote or local repository based on the network status
     * @param credentials - credentials of the user trying to login
     * @returns Logged in user domain object
     */
    async login(credentials: StandardLoginCredentials): Promise<User> {
        const start = performance.now();
        this.logger.debug(`Starting Standard Login`);

        this.logger.debug(`Determining network status`);
        const isOnline = this.networkStatusService.getCombinedOnlineStatus();

        const user = isOnline
            ? await this.onlineLogin(credentials)
            : await this.offlineLogin(credentials);

        const end = performance.now();
        this.logger.debug(
            `Standard Login completed in ${end - start} milliseconds.`
        );

        return user;
    }

    /**
     * Login the user, get user detail and save the complete user object to indexed db
     * @param credentials
     * @returns User domain model
     */
    private async onlineLogin(
        credentials: StandardLoginCredentials
    ): Promise<User> {
        this.logger.debug("User logging in through online flow");

        const onlineUser = await this.remoteRepository.login(credentials);

        this.logger.debug("Getting user detailed information");
        const detailedUser =
            await this.remoteRepository.getUserDetailedInformation(
                credentials,
                onlineUser.id
            );

        this.logger.debug("Saving user to indexed db");
        return await this.localRepository.saveUser(detailedUser);
    }

    /**
     * Login the user through local repository
     * @param credentials
     * @returns User domain model
     */
    private async offlineLogin(
        credentials: StandardLoginCredentials
    ): Promise<User> {
        this.logger.debug("User logging in through offline flow");

        return this.localRepository.login(credentials);
    }
}
