import { Injectable, inject } from "@angular/core";
import { CipherUtilService } from "../../../../shared/infrastructure/services/cipher-util.service";
import { ILogger } from "../../../logging/models/logger.model";
import { LOGGER } from "../../../logging/providers/logger.provider";

@Injectable({
    providedIn: "root",
})
export class AuthUtilService {
    private logger: ILogger = inject(LOGGER);
    private cipherUtilService: CipherUtilService = inject(CipherUtilService);

    /**
     * Generating private key based on user's credentials
     *  @param username username of the user
     *  @param password password of the user
     * @returns a private key generated based on users credentials
     */
    async generatePrivateKey(
        username: string,
        password: string
    ): Promise<string> {
        const start = performance.now();
        this.logger.debug("Starting private key generation");

        const privateKey = await this.cipherUtilService.getSha256String(
            username.toLowerCase() + password,
            true
        );

        const end = performance.now();
        this.logger.debug(
            `Private key generated in ${end - start} milliseconds.`
        );

        return privateKey;
    }
}
