import { Injectable } from "@angular/core";
import { Mapper } from "../../../core/base/mapper";
import { UserToken } from "../../core/domain/user-token.model";
import { UserTokenDTO } from "../model/user-token.dto";

@Injectable({
    providedIn: "root",
})
export class UserTokenMapper extends Mapper<UserTokenDTO, UserToken> {
    /**
     * Maps from UserTokenDTO to UserToken
     * @dto - The UserTokenDTO to map from.
     * @returns The mapped UserToken model
     */
    override mapFrom(dto: UserTokenDTO): UserToken {
        const model = new UserToken();
        Object.assign(model, dto);

        return model;
    }

    /**
     * Maps from UserToken model to UserTokenDTO.
     * @model UserToken - The UserToken model to map from.
     * @returns The mapped UserTokenDTO
     */
    override mapTo(model: UserToken): UserTokenDTO {
        const dto = new UserTokenDTO();
        Object.assign(dto, model);

        return dto;
    }
}
