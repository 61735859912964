import { Injectable } from "@angular/core";
import { Mapper } from "../../../core/base/mapper";
import { UserDetailedInfoDTO } from "../model/user-detailed-info.dto";
import { UserDetailedInfo } from "../../core/domain/user-detailed-info.model";

/**
 * Mapper class for converting between UserDetailedInfoDTO and UserDetailedInfo
 */
@Injectable({
    providedIn: "root",
})
export class UserDetailedInformationMapper extends Mapper<
    UserDetailedInfoDTO,
    UserDetailedInfo
> {
    /**
     * Maps from UserDetailedInfoDTO to UserDetailedInfo
     * @dto - The UserDetailedInfoDTO to map from
     * @returns The mapped UserDetailedInfo model
     */
    override mapFrom(dto: UserDetailedInfoDTO): UserDetailedInfo {
        const model = new UserDetailedInfo();
        Object.assign(model, dto);

        return model;
    }

    /**
     * Maps from UserDetailedInfo model to UserDetailedInfoDTO
     * @model - The UserDetailedInfo model to map from
     * @returns The mapped UserDetailedInfoDTO
     */
    override mapTo(model: UserDetailedInfo): UserDetailedInfoDTO {
        const dto = new UserDetailedInfoDTO();
        Object.assign(dto, model);

        return dto;
    }
}
