import { Injectable, inject } from "@angular/core";
import { UserTokenBaseRepository } from "../../../core/base/user-token-base.repository";
import { StandardLoginCredentials } from "../../../core/domain/standard-login-credentials.model";
import { UserTokenLocalRepository } from "./user-token-local.repository";
import { UserTokenRemoteRepository } from "./user-token-remote.repository";
import { UserToken } from "../../../core/domain/user-token.model";
import { NetworkStatusService } from "../../../../shared/infrastructure/services/network-status.service";
import { ILogger } from "../../../../core/logging/models/logger.model";
import { LOGGER } from "../../../../core/logging/providers/logger.provider";

@Injectable({
    providedIn: "root",
})
export class UserTokenRepository extends UserTokenBaseRepository<StandardLoginCredentials> {
    private logger: ILogger = inject(LOGGER);
    private localRepository: UserTokenLocalRepository = inject(
        UserTokenLocalRepository
    );
    private remoteRepository: UserTokenRemoteRepository = inject(
        UserTokenRemoteRepository
    );
    private networkStatusService: NetworkStatusService =
        inject(NetworkStatusService);

    /**
     * Generate user token from remote or local repository based on network online status
     * @param credentials credentials of the user
     * @returns user token domain object or null
     */
    async getUserToken(
        credentials: StandardLoginCredentials
    ): Promise<UserToken> {
        const start = performance.now();
        this.logger.debug("Fetching user token");

        let userToken: UserToken;

        const isOnline = this.networkStatusService.getCombinedOnlineStatus();

        if (isOnline) {
            this.logger.debug("Fetching user token through online flow");
            userToken = await this.remoteRepository.getUserToken(credentials);

            this.logger.debug("Saving user token to indexed db");
            await this.localRepository.saveUserToken(
                credentials.username,
                credentials.password,
                userToken
            );
        } else {
            this.logger.debug("Fetching user token through offline flow");

            userToken = await this.localRepository.getUserToken(credentials);
        }

        const end = performance.now();
        this.logger.debug(
            `Get user token completed in ${end - start} milliseconds.`
        );

        return userToken;
    }
}
