import { Injectable, inject } from "@angular/core";
import { Usecase } from "../../../core/base/usecase";
import { StandardLoginRepository } from "../../infrastructure/persistence/standard-login/standard-login.repository";
import { User } from "../domain/user.model";
import { StandardLoginCredentials } from "../domain/standard-login-credentials.model";

@Injectable({
    providedIn: "root",
})
export class StandardLoginUsecase extends Usecase<
    StandardLoginCredentials,
    Promise<User>
> {
    private loginRepository: StandardLoginRepository = inject(
        StandardLoginRepository
    );

    /**
     * Login the user with the provided credentials
     * @param credentials credentials of the user needed to login
     * @returns User object
     */
    execute(credentials: StandardLoginCredentials): Promise<User> {
        this.logger.debug("Executing StandardLoginUsecase");
        return this.loginRepository.login(credentials);
    }
}
