import { HttpClient, HttpHeaders } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { Injectable, inject } from "@angular/core";
import { LoginBaseRepository } from "../../../core/base/login-base.repository";
import { UserMapper } from "../../../data/transformation/user.mapper";
import { User } from "../../../core/domain/user.model";
import { UserDTO } from "../../../data/model/user.dto";
import { StandardLoginCredentials } from "../../../core/domain/standard-login-credentials.model";
import { ILogger } from "../../../../core/logging/models/logger.model";
import { LOGGER } from "../../../../core/logging/providers/logger.provider";

@Injectable({
    providedIn: "root",
})
export class StandardLoginRemoteRepository extends LoginBaseRepository<StandardLoginCredentials> {
    private logger: ILogger = inject(LOGGER);
    private userMapper: UserMapper = inject(UserMapper);
    private http: HttpClient = inject(HttpClient);

    /**
     * Login the user with the provided credentials from API call to server
     * @param credentials - credentials of the user trying to login
     * @returns Logged in user domain object
     */
    async login(credentials: StandardLoginCredentials): Promise<User> {
        const start = performance.now();
        this.logger.debug("Starting StandardLoginRemoteRepo login");

        const httpHeaders = this.getHeaders(credentials);

        const user = await firstValueFrom(
            this.http
                .post<UserDTO>(
                    `${environment.routes.mCase.routes.login.url}`,
                    null,
                    {
                        headers: httpHeaders,
                    }
                )
                .pipe(map((user) => this.userMapper.mapFrom(user)))
        );

        const end = performance.now();
        this.logger.debug(
            `StandardLoginRemoteRepo login completed in ${end - start} milliseconds.`
        );

        return user;
    }

    /**
     * Generate user detailed information
     * @param credentials StandardLoginCredentials
     * @param userId id of the user
     * @returns user domain object
     */
    async getUserDetailedInformation(
        credentials: StandardLoginCredentials,
        userId: number
    ): Promise<User> {
        const start = performance.now();
        this.logger.debug(
            "Starting UserDetailedRemoteRepository getUserDetailedInformation"
        );

        const httpHeaders = this.getHeaders(credentials);

        const user = await firstValueFrom(
            this.http
                .get<UserDTO>(
                    `${environment.routes.mCase.routes.userDetailed.url}/${userId}`,
                    {
                        headers: httpHeaders,
                    }
                )
                .pipe(map((user) => this.userMapper.mapFrom(user)))
        );

        const end = performance.now();
        this.logger.debug(
            `UserDetailedRemoteRepository getUserDetailedInformation completed in ${end - start} milliseconds.`
        );

        return user;
    }

    private getHeaders(credentials: StandardLoginCredentials): HttpHeaders {
        const authCredentials = `${credentials.username}:${credentials.password}`;

        return new HttpHeaders({
            Authorization: `Basic ${btoa(authCredentials)}`,
        });
    }
}
