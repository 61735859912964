import { LoginCredentials } from "../type/login-credentials.type";
import { UserToken } from "../domain/user-token.model";

/**
 * Represents the base repository for getting user token.
 */
export abstract class UserTokenBaseRepository<T extends LoginCredentials> {
    /**
     * Get user token
     * @param credentials - credentials of the user
     * @returns user token
     */
    abstract getUserToken(credentials: T): Promise<UserToken | null>;
}
