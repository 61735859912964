import { LoginCredentials } from "../type/login-credentials.type";
import { User } from "../domain/user.model";

/**
 * Represents the base repository for managing login.
 */
export abstract class LoginBaseRepository<T extends LoginCredentials> {
    /**
     * Login the user
     * @param credentials - credentials of the user
     * @returns User object.
     */
    abstract login(credentials: T): Promise<User>;
}
