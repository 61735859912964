import { Injectable, inject } from "@angular/core";
import { UserTokenBaseRepository } from "../../../core/base/user-token-base.repository";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { StandardLoginCredentials } from "../../../core/domain/standard-login-credentials.model";
import { UserTokenDTO } from "../../../data/model/user-token.dto";
import { UserTokenMapper } from "../../../data/transformation/user-token.mapper";
import { map } from "rxjs/operators";
import { ILogger } from "../../../../core/logging/models/logger.model";
import { LOGGER } from "../../../../core/logging/providers/logger.provider";
import { UserToken } from "../../../core/domain/user-token.model";

@Injectable({
    providedIn: "root",
})
export class UserTokenRemoteRepository extends UserTokenBaseRepository<StandardLoginCredentials> {
    private logger: ILogger = inject(LOGGER);
    private http: HttpClient = inject(HttpClient);
    private userTokenMapper: UserTokenMapper = inject(UserTokenMapper);

    /**
     * Generate user token based on user credentials from server
     * @param credentials credentials of the user
     * @returns user token dto object
     */
    async getUserToken(
        credentials: StandardLoginCredentials
    ): Promise<UserToken> {
        const start = performance.now();
        this.logger.debug("Starting UserTokenRemoteRepo getUserToken");

        const authCredentials = `${credentials.username}:${credentials.password}`;
        const httpHeaders = new HttpHeaders({
            Authorization: `Basic ${btoa(authCredentials)}`,
        });

        const userToken = firstValueFrom(
            this.http
                .get<UserTokenDTO>(
                    `${environment.routes.mCase.routes.getToken.url}`,
                    {
                        headers: httpHeaders,
                    }
                )
                .pipe(
                    map((userTokenDTO) =>
                        this.userTokenMapper.mapFrom(userTokenDTO)
                    )
                )
        );

        const end = performance.now();
        this.logger.debug(
            `UserTokenRemoteRepo getUserToken completed in ${end - start} milliseconds.`
        );

        return userToken;
    }
}
