import { Injectable, inject } from "@angular/core";
import { LoginBaseRepository } from "../../../core/base/login-base.repository";
import { StandardLoginCredentials } from "../../../core/domain/standard-login-credentials.model";
import { User } from "../../../core/domain/user.model";
import { ILogger } from "../../../../core/logging/models/logger.model";
import { LOGGER } from "../../../../core/logging/providers/logger.provider";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { CipherUtilService } from "../../../../shared/infrastructure/services/cipher-util.service";
import { EncryptedData } from "../../../../shared/infrastructure/model/encrypted-data.model";
import { AuthUtilService } from "../../../../core/auth/infrastructure/services/auth-util.service";
import { UserDTO } from "../../../data/model/user.dto";
import { UserMapper } from "../../../data/transformation/user.mapper";
import { DB_TABLES } from "../../../../core/data-access/enums/table-list.enum";
import { GenericLookUpStorageDTO } from "../../../../core/data/model/generic-look-up-storage.dto";
import { GENERIC_LOOKUP_STORAGE_KEYS } from "../../../../core/data/enum/generic-look-up-storage-keys.enum";
import { DataAccess } from "../../../../infrastructure/data-access/data-access";
import { DATA_ACCESS_SERVICE } from "../../../../core/data-access/providers/data-access.provider";
import { MCaseError } from "../../../../shared/infrastructure/error/mcase.error";

@Injectable({
    providedIn: "root",
})
export class StandardLoginLocalRepository extends LoginBaseRepository<StandardLoginCredentials> {
    private userMapper: UserMapper = inject(UserMapper);
    private logger: ILogger = inject(LOGGER);
    private authUtilService: AuthUtilService = inject(AuthUtilService);
    private cipherUtilService: CipherUtilService = inject(CipherUtilService);
    private dataAccess: DataAccess = inject(DATA_ACCESS_SERVICE);

    /**
     * Login the user with the provided credentials from indexed db
     * @param credentials - credentials of the user trying to login
     * @returns Logged in user domain object
     */
    async login(credentials: StandardLoginCredentials): Promise<User> {
        const start = performance.now();
        this.logger.debug("Starting StandardLoginLocalRepo login");

        // get user from indexed db
        this.logger.debug("Fetching user from indexed db");
        const userLookUpDto: GenericLookUpStorageDTO<UserDTO> | null =
            await this.dataAccess.read(
                DB_TABLES.GENERIC_LOOKUP,
                GENERIC_LOOKUP_STORAGE_KEYS.USER
            );

        // user not available in indexed db
        if (!userLookUpDto || !userLookUpDto.value) {
            throw new MCaseError(
                "You must be online to log in for the first time or after the application has been reset. Please connect to the internet and try again"
            );
        }

        const userDto = userLookUpDto.value;

        // validate user trying to login with the user stored in indexed db
        this.logger.debug("Validating user with user stored in indexed db");
        const hasValidCredentials = await this.validateCredentials(
            credentials.username,
            credentials.password
        );

        if (hasValidCredentials) {
            const end = performance.now();
            this.logger.debug(
                `StandardLoginRemoteRepo login completed in ${end - start} milliseconds.`
            );

            return this.userMapper.mapFrom(userDto);
        } else {
            throw new HttpErrorResponse({
                status: HttpStatusCode.Unauthorized,
            });
        }
    }

    /**
     * Validate user trying to login with the user stored in indexed db
     * @param username - username of the user
     * @param password - password of the user
     * @returns a boolean indicating the validation status
     */
    async validateCredentials(
        username: string,
        password: string
    ): Promise<boolean> {
        const start = performance.now();
        this.logger.debug("Starting credential validation");

        this.logger.debug("Generating private key");
        const privateKey = await this.authUtilService.generatePrivateKey(
            username,
            password
        );
        try {
            // get encrypted token from indexed db
            this.logger.debug("Fetching user token stored in indexed db");
            const encryptedUserTokenLookUpDTO: GenericLookUpStorageDTO<EncryptedData> | null =
                await this.dataAccess.read(
                    DB_TABLES.GENERIC_LOOKUP,
                    GENERIC_LOOKUP_STORAGE_KEYS.USER_TOKEN
                );

            if (
                !encryptedUserTokenLookUpDTO ||
                !encryptedUserTokenLookUpDTO.value
            ) {
                this.logger.error(
                    "User Token not found @ standard.login-local.repository.ts:validateCredentials()"
                );

                return false;
            }

            const encryptedUserTokenString = encryptedUserTokenLookUpDTO.value;

            // decrypt the stored encrypted token the privateKey generated from the credentials of the user
            this.logger.debug("Decrypting stored user token");
            await this.cipherUtilService.decrypt(
                encryptedUserTokenString,
                privateKey
            );

            const end = performance.now();
            this.logger.debug(
                `Credentials validated in ${end - start} milliseconds.`
            );

            return true;
        } catch (error) {
            // Validation failed, invalid credentials
            this.logger.error(
                "Validation of credentials failed @ auth.service.ts:validateCredentials()",
                error
            );
            return false;
        }
    }

    /**
     * Save user dto object to indexed db
     * @param user - user domain object
     * @returns user domain object
     */
    async saveUser(user: User): Promise<User> {
        const start = performance.now();
        this.logger.debug("Saving user to indexed db");

        const userDTO = this.userMapper.mapTo(user);
        const storageDTO = new GenericLookUpStorageDTO(
            GENERIC_LOOKUP_STORAGE_KEYS.USER,
            userDTO
        );

        await this.dataAccess.create(DB_TABLES.GENERIC_LOOKUP, storageDTO);

        const end = performance.now();
        this.logger.debug(
            `Saving user to indexed db completed in ${end - start} milliseconds.`
        );

        return user;
    }
}
