/**
 * Represents detailed information about a user.
 */
export class UserDetailedInfoDTO {
    /**
     * The base64 encoded image of the user.
     */
    base64Img: string = "";

    /**
     * A comment associated with the user.
     */
    comment: string | null = null;

    /**
     * Indicates whether email notifications are enabled for the user.
     */
    emailNotification: boolean = false;

    /**
     * Indicates whether external authorization is enabled for the user.
     */
    externalAuthorization: boolean = false;

    /**
     * Indicates whether the user is approved.
     */
    isApproved: boolean = false;

    /**
     * Indicates whether the user is locked out.
     */
    isLockedOut: boolean = false;

    /**
     * Indicates whether an offline PIN is set for the user.
     */
    isOfflinePinSet: boolean = false;

    /**
     * The mobile number associated with the user.
     */
    mobileNumber: string | null = null;

    /**
     * The new password for the user.
     */
    newPassword: string | null = null;

    /**
     * The confirmation of the new password for the user.
     */
    newPasswordConfirmation: string | null = null;

    /**
     * The offline PIN for the user.
     */
    offlinePin: string | null = null;

    /**
     * The password for the user.
     */
    password: string | null = null;

    /**
     * The answer to the password recovery question for the user.
     */
    passwordAnswer: string | null = null;

    /**
     * The password recovery question for the user.
     */
    passwordQuestion: string | null = null;

    /**
     * The privilege level of the user.
     */
    privilegeLevel: number = 0;

    /**
     * Indicates whether the user is required to set a new password.
     */
    requireNewPassword: boolean = false;

    /**
     * Indicates whether text notifications are enabled for the user.
     */
    textNotification: boolean = false;
}
